<template>
  <div class="container">
    <div class="container__control">
      <ClosePopUpButton
        class="black"
      />
    </div>

    <div class="container__content">
      <h3>
        {{ $t('components.ui.pages.pop-up.wallet-delete.name') }}
      </h3>

      <p>
        {{ $t('components.ui.pages.pop-up.wallet-delete.text') }}
      </p>

      <SelectInput
        style="margin: 20px 0;"
        class="wrap"
        :placeholder="$t('components.ui.pages.pop-up.wallet-delete.choose')"
        :list="_wallets"
        label="name"
        v-model="selected"
      />

      <BlueButton
        style="margin-top: 20px;"
        class="light"
        :text="$t('components.ui.pages.pop-up.wallet-delete.button')"
        :disabled="!selected"
        @click="submit()"
      />
    </div>
  </div>
</template>

<script>
import ClosePopUpButton from '@/ui/buttons/ClosePopUpButton.vue'
import SelectInput from '@/ui/inputs/Select.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'

export default {
  components: {
    ClosePopUpButton,
    SelectInput,
    BlueButton
  },
  data () {
    return {
      selected: null
    }
  },
  computed: {
    _wallets () {
      const original = this.$store.state.withdrawal.list
      const list = []

      for (const i in original) {
        list.push({
          id: original[i].id,
          name: original[i].address
        })
      }

      return list
    }
  },
  methods: {
    submit () {
      this.$pop.up({
        name: 'deleteWalletConfirm',
        value: this.selected
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  max-width: 430px;
  padding: 10px;
  background: white;
  border-radius: 15px;

  .container__control {
    display: flex;
    justify-content: flex-end;
  }

  .container__content {
    padding: 0 10px 10px;

    h3 {
      line-height: 100%;
      color: #202539;
      font-family: SemiBold;
    }

    p {
      margin: 20px 0;
      font-size: 16px;
      line-height: 120%;
      color: #202539;
    }
  }
}
</style>
